import React from 'react';
import Metadata from '@/components/Metadata/Metadata';
import { SOFTQUOTE_METADATA } from '@/components/Metadata/Metadata.constants';
import {RetrieveQuote} from '@/components/PersonalLoanSoftQuote/retrieve/RetrieveQuote';

// TODO: Add the datadog appender
export default props => {
  return (
    <>
      <Metadata {...SOFTQUOTE_METADATA} />
      <RetrieveQuote {...props} isGem />
    </>
  );
};
